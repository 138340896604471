import {
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT,
	VERIFY_EMAIL_SUCCESS,
	VERIFY_EMAIL_FAIL,
} from "../actions/types";

const initialState = {
	token: localStorage.getItem("token"),
	isAuthenticated: localStorage.getItem("token") ? true : false,
	userId: null
};

const auth_reducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case LOGIN_SUCCESS:
			localStorage.setItem("token", payload.authorization);
			return {
				...state,
				isAuthenticated: true,
				token: payload.authorization,
				userId: payload.userid
			};
		case LOGOUT:
		case LOGIN_FAIL:
			localStorage.removeItem("token");
			return {
				...state,
				isAuthenticated: false,
				token: null,
				refresh: null,
				userId: null
			};
		case VERIFY_EMAIL_SUCCESS:
		case VERIFY_EMAIL_FAIL:
		default:
			return {
				...state,
			};
	}
};

export default auth_reducer;

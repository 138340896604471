export const quoteInitState = {
	totalPrice: 0,
	note: "",

	generator: {},
	enclosure: {},
	breaker: {
		primary: {},
		primaryAccessories: [],
		secondary: {},
		secondaryAccessories: [],
	},
	fuelTank: {
		fuelTank: {},
		accessories: [],
	},
	otherProducts: [],
};

export const generatorOptionsStationary = [
	{
		key: "application",
		title: "Applications",
		selected: "Emergency Stationary",
		items: ["Emergency Stationary", "Mobile / Rental"],
	},
	{
		key: "fuelType",
		title: "Fuel Types",
		selected: "Diesel",
		items: ["Diesel", "Gaseous"],
	},
	{
		key: "voltage",
		title: "Voltage",
		selected: "208/120V",
		items: ["208/120V", "240/120V", "480/277V", "600/347V", "240/120V, 1Ph"],
	},
	{
		key: "leads",
		title: "Leads",
		selected: "12",
		items: ["12"],
	},
	{
		key: "kwRange",
		title: "KW Range",
		selected: "1-49",
		items: ["1-49", "50-124", "125-249", "250-449", "450-699", "700-1099", "1100-above"],
	},
	{
		key: "certification",
		title: "Certification",
		selected: "EPA Certified, UL2200",
		items: ["EPA Certified, UL2200"],
	},
];

export const generatorOptionsStationaryVoltageLeads = {
	"208/127V": [12], 
	"240/120V": [12], 
	"480/277V": [12], 
	"600/347V": [6], 
	"240/120V, 1Ph": [12, 4],
}


export const generatorOptionsMobile = [
	{
		key: "application",
		title: "Applications",
		selected: "Mobile / Rental",
		items: ["Emergency Stationary", "Mobile / Rental"],
	},
	{
		key: "fuelType",
		title: "Fuel Types",
		selected: "Diesel",
		items: ["Diesel"],
	},
	{
		key: "voltage",
		title: "Voltage",
		selected: "Multi Voltage",
		items: ["Multi Voltage"],
	},
	{
		key: "leads",
		title: "Leads",
		selected: "12",
		items: ["12"],
	},
	{
		key: "kwRange",
		title: "KW Range",
		selected: "1-99",
		items: ["1-99", "100-300", "301-700"],
	},
	{
		key: "certification",
		title: "Certification",
		selected: "EPA Certified, TIER 4 FINAL",
		items: ["EPA Certified, TIER 4 FINAL"],
	},
];

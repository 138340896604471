import {
	QUOTE_UPDATE,
	USER_LOAD_SUCCESS,
	USER_LOAD_FAIL,
	APP_SETTINGS_LOAD_SUCCESS,
	APP_SETTINGS_LOAD_FAIL,
	APP_SETTINGS_UPDATE_SUCCESS,
	APP_SETTINGS_UPDATE_FAIL
} from "../actions/types";

import { quoteInitState } from "../constants";

const initialState = {
	appSettings: null,
	user: null,
	quote: quoteInitState
};

const data_reducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case QUOTE_UPDATE:
			return {
				...state,
				quote: payload,
			};
		case USER_LOAD_SUCCESS:
			return {
				...state,
				user: payload,
			};
		case USER_LOAD_FAIL:
			return {
				...state,
				user: null,
			};
		case APP_SETTINGS_LOAD_SUCCESS:
		case APP_SETTINGS_UPDATE_SUCCESS:
			return {
				...state,
				appSettings: payload,
			};
		case APP_SETTINGS_LOAD_FAIL:
		case APP_SETTINGS_UPDATE_FAIL:
			return {
				...state,
				appSettings: null,
			};
		default:
			return {
				...state,
			};
	}
};

export default data_reducer;

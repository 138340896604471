import React, { Component, Suspense } from "react";
import Layout from "./components/Layout/";
import { Route, Switch, BrowserRouter as Router, withRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";

// Import Css
import "./assets/css/materialdesignicons.min.css";
import "./assets/css/colors/default.css";
import "./Apps.scss";

// Include Routes
import routes from "./routes";

function withLayout(WrappedComponent, isTopbarFloat) {
	// ...and returns another component...
	return class extends React.Component {
		render() {
			return (
				<Layout isTopbarFloat={isTopbarFloat}>
					<WrappedComponent></WrappedComponent>
				</Layout>
			);
		}
	};
}
const Loader = () => {
	return (
		<div id="preloader">
			<div id="status">
				<div className="spinner">
					<div className="double-bounce1"></div>
					<div className="double-bounce2"></div>
				</div>
			</div>
		</div>
	);
};

class App extends Component {
	render() {
		return (
			<Provider store={store}>
				<React.Fragment>
					<Router>
						<Suspense fallback={<Loader />}>
							<Switch>
								{routes.map((route, idx) =>
									route.isWithoutLayout ? (
										<Route
											path={route.path}
											exact={route.exact}
											component={route.component}
											key={idx}
										/>
									) : (
										<Route
											path={route.path}
											exact
											component={withLayout(route.component, route.isTopbarFloat)}
											key={idx}
										/>
									)
								)}
							</Switch>
						</Suspense>
					</Router>
				</React.Fragment>
			</Provider>
		);
	}
}

export default withRouter(App);

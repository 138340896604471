export const logoLight = require('../../assets/images/logo/teksan-logo-light.png').default;
export const logoDark = require('../../assets/images/logo/teksan-logo-dark.png').default;
export const mapText = require('../../assets/images/teksan/footer_map.png').default;
export const mapImage = require('../../assets/images/teksan/footer_map2.png').default;
export const map = require('../../assets/images/teksan/map2.png').default;

export const storeGoogle = require('../../assets/images/teksan/google-en.svg').default;
export const storeApple = require('../../assets/images/teksan/apple-en.svg').default;
export const teksanQr = require('../../assets/images/teksan/teksan-store-qr.svg').default;

export const iconFacebook = require('../../assets/images/teksan/icon_facebook.png').default;
export const iconYoutube = require('../../assets/images/teksan/icon_youtube.png').default;
export const iconLinkedin = require('../../assets/images/teksan/icon_linkedin.png').default;
export const iconTwitter = require('../../assets/images/teksan/icon_twitter.png').default;
export const iconInstagram = require('../../assets/images/teksan/icon_instagram.png').default;

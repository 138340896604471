import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Container, Button } from "reactstrap";

import {navLinksGuest, navLinksAuth} from './Menu';

//Import images
import { logoDark } from "./Images";



const Topbar = ({isAuthenticated, isAdmin}) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [navLinks, setNavLinks] = useState(navLinksGuest);

	useEffect(() => {
		if (isAuthenticated) {
			// if(isAdmin) {
			// 	setNavLinks(navLinksAuthAdmin);
			// }
			// else {
			// 	setNavLinks(navLinksAuth);
			// }
			setNavLinks(navLinksAuth);
		}
		
	},[isAuthenticated, isAdmin]);


	return (
		<React.Fragment>
			<header id="header-teksan">
				<Container id="header-teksan-container">
					<Link to="/">
						<div id="header-logo"></div>
					</Link>

						<ul id="teksan-navigation">
							{navLinks.map((navLink, key) => (
								<li key={key}>
									{/* <Link to={navLink.link}>
										<Button type="button" className="btn-header">
											{navLink.title}
										</Button>
									</Link> */}
									<Link to={navLink.link} className="btn teksan-btn header-btn">
										{navLink.title}
									</Link>
								</li>
							))}
						</ul>

				</Container>
			</header>
			{/* <header id="topnav" className="defaultscroll sticky">
				<Container>
					<div>
						<Link className="logo" to="/">
							<img src={logoDark} id="brandLogo" height="24" alt="" />
						</Link>
					</div>
					<div className="menu-extras">
						<div className="menu-item">
							<Link
								to="#"
								onClick={() => setIsMenuOpen(!isMenuOpen)}
								className={isMenuOpen ? "navbar-toggle open" : "navbar-toggle"}
							>
								<div className="lines">
									<span></span>
									<span></span>
									<span></span>
								</div>
							</Link>
						</div>
					</div>

					<div id="navigation" style={{ display: "block" }}>
						<ul className="navigation-menu justify-content-end" id="top-menu">
							{navLinks.map((navLink, key) => (
								<li key={key}>
									<Link to={navLink.link}>{navLink.title}</Link>
								</li>
							))}
						</ul>
					</div>
				</Container>
			</header> */}
		</React.Fragment>
	);
};

const mapStateToProps = (state) => ({
	isAuthenticated: state.auth.isAuthenticated,
	isAdmin: state.data.user ? state.data.user.isAdmin : false,
});

export default connect(mapStateToProps)(withRouter(Topbar));

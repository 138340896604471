export const navLinksGuest = [
	{ id: 1, title: "Login", link: "/login" },
	{ id: 2, title: "Signup", link: "/signup" },
];
export const navLinksAuth = [
	{ id: 1, title: "Logout", link: "/logout" },
];
export const navLinksAuthUser = [
	{ id: 2, title: "Generate Quote", link: "/generate-quote" },
	{ id: 3, title: "View Quotes", link: "/quotes" },
	{ id: 4, title: "Stock List", link: "/stocks" },
];
export const navLinksAuthAdmin = [
	{ id: 2, title: "Generate Quote", link: "/generate-quote" },
	{ id: 3, title: "View Quotes", link: "/quotes" },
	{ id: 4, title: "Stock List", link: "/stocks" },
	{ id: 5, title: "Users", link: "/admin/users" },
	{ id: 6, title: "User Categories", link: "/admin/user-categories" },
	{ id: 7, title: "App Settings", link: "/admin/app-settings" },
	{ id: 8, title: "Data Sync", link: "/admin/data-sync" },
];
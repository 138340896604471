import React, { useEffect, useLayoutEffect, Suspense } from "react";
import { withRouter } from "react-router-dom";
// import { connect } from "react-redux";
// import { checkAuthenticated } from "../../actions/auth";
import ScrollUpButton from "react-scroll-up-button";

//Import Icons and Images
import FeatherIcon from "feather-icons-react";
import { logoLight, logoDark } from "./Images";

// Layout Components
//const Topbar = React.lazy(() => import("./Topbar"));
import Topbar from "./Topbar";
const Footer = React.lazy(() => import("./Footer"));


const CustomDot = () => {
	return (
		<i>
			<FeatherIcon icon="arrow-up" className="icons" />
		</i>
	);
};

const Loader = () => {
	return (
	  <div id="preloader">
		<div id="status">
		  <div className="spinner">
			<div className="double-bounce1"></div>
			<div className="double-bounce2"></div>
		  </div>
		</div>
	  </div>
	);
  };

const scrollNavigation = () => {
	var doc = document.documentElement;
	//document.getElementById("topnav").classList.add("nav-sticky");
	//document.getElementById("brandLogo").src = logoDark;
	// var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
	// if (top > 80) {
	// 	document.getElementById("topnav").classList.add("nav-sticky");
	// 	document.getElementById("brandLogo").src = logoDark;
	// } 
	// else {
	// 	document.getElementById("topnav").classList.remove("nav-sticky");
	// 	document.getElementById("brandLogo").src = logoLight;
	// }
};

const Layout = (props) => {
	// useEffect(() => {
	// 	props.checkAuthenticated();
	// }, [props]);

	useEffect(() => {
		document.body.classList = "";

		if (props.isTopbarFloat) {
			// document.getElementById("top-menu").classList.add("nav-light");
			// document.getElementById("brandLogo").src = logoLight;
			window.addEventListener("scroll", scrollNavigation, true);
		}
		else {
			// document.getElementById("topnav").classList.add("nav-sticky");
			// document.getElementById("brandLogo").src = logoDark;
			window.removeEventListener("scroll", scrollNavigation, true);
		}
	},[]);

	return (
		<React.Fragment>
			<Suspense fallback={<Loader/>}>
				<Topbar/>
				<div className="full-page">
					<div className="content">
						{props.children}
					</div>
				</div>
				<Footer />

				{/* <div className="btn btn-icon btn-soft-primary back-to-top"> */}
				<ScrollUpButton
					ContainerClassName="classForContainer"
					style={{ height: 36, width: 36 }}
					TransitionClassName="classForTransition"
				>
					<CustomDot />
				</ScrollUpButton>
				{/* </div> */}
			</Suspense>
		</React.Fragment>
	);
};

export default withRouter(Layout);
